<template>
  <v-alert
    class="warning-farm-disabled mb-0"
    prominent
    text-color="#4F430C"
    color="#FFF2B0"
  >
    <v-row align="center" no-gutters>
      <v-icon class="mr-2 pl-2 pr-2" color="#A68B08">mdi-alert</v-icon>
      <v-col class="grow">
        {{ $t('password_recovery.temporary_confirmation') }}
      </v-col>
      <v-row class="shrink actions" no-gutters>
        <v-btn
          small
          class="text-none text-button-action success"
          @click="openModalSenha"
        >
          {{ $t('yes') }}
        </v-btn>
      </v-row>
    </v-row>
    <modal-senha v-if="isModalSenhaOpened" @close="closeModalSenha" />
  </v-alert>
</template>

<script>
const ModalSenha = () =>
  import('@/modules/menu/components/modals/ModalSenha.vue')

export default {
  name: 'WarningPasswordRecovery',

  components: {
    ModalSenha,
  },

  data() {
    return {
      isModalSenhaOpened: false,
    }
  },

  methods: {
    closeModalSenha() {
      this.isModalSenhaOpened = false
    },
    openModalSenha() {
      this.isModalSenhaOpened = true
    },
  },
}
</script>

<style lang="scss" scoped>
.actions {
  gap: 16px;

  .text-button-action {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    color: #fff !important;

    .success {
      background-color: #39af49;
    }
    .error {
      background-color: #f23528;
    }
  }
}
</style>
